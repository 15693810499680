import React, { FC, useMemo } from 'react';

import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import { useRouter } from 'next/router';

import { ComposerLayout, ComposerPage, Deployment } from '@types';

import { ThemeBlock } from '@application/components/Theme/model/theme-block';
import { ThemeProvider } from '@application/components/ds';

import { customTheme, NextFontLoader } from '@application/theme/utils';

import { GetLastProjectDeploymentByDomain } from '@services/clients/graphql/admin/deployment/queries';

import { CompilerProvider } from '@application/compiler/contexts';
import { DynamicPropsWrapper } from '@application/compiler/wrappers';
import { EngineWrapper } from '@application/compiler/wrappers/EngineWrapper';
import { ProjectCompiler } from '@application/compiler/ProjectCompiler';

import { DeploymentNotFound } from '@deployments/components';

interface PageProps {
  deployment?: Deployment;
  domain: string;
}

const DeploymentPage: FC<PageProps> = ({ deployment, domain }) => {
  const router = useRouter();

  const { pages = [], layouts = [] } = useMemo(() => {
    const layouts: ComposerLayout[] = [];
    const pages: ComposerPage[] = [];

    if (!deployment?.pages?.length) {
      return {
        pages,
        layouts,
      };
    }

    if (deployment.layouts) {
      const parsedLayoutArr = JSON.parse(deployment.layouts);

      if (parsedLayoutArr.length) {
        const layout = parsedLayoutArr[0];

        if (layout?.nodesStructure) {
          layout.nodesStructure = JSON.parse(layout.nodesStructure);
        }

        layouts.push(layout);
      }
    }

    if (deployment.pages?.length) {
      for (const page of deployment.pages) {
        if (typeof page.nodesStructure === 'string') {
          page.nodesStructure = JSON.parse(page.nodesStructure);
        }

        // @ts-ignore
        pages.push(page);
      }
    }

    return {
      pages,
      layouts,
    };
  }, [deployment]);

  const deploymentPath = router?.query?.deploymentPath ?? '';
  const baseDeploymentPath = '/';

  const currentPath = Array.isArray(deploymentPath) ? deploymentPath : [''];
  const currentRoute = ['', ...currentPath].join('/');

  const themeBlock: ThemeBlock = deployment?.theme && {
    ...deployment.theme,
    theme: JSON.parse(deployment.theme.theme),
  };

  // @ts-ignore
  const { auth, api, accountId } = deployment?.config ? JSON.parse(JSON.parse(deployment?.config)) : {};

  const compilerRoutePush = ({ path }: { path: string }) => {
    return router.push(`${baseDeploymentPath}${path}`, undefined, { shallow: true });
  };

  return (
    <DeploymentNotFound deployment={deployment}>
      <ThemeProvider theme={customTheme(themeBlock)}>
        <NextFontLoader fonts={themeBlock?.customFonts?.map(({ link }) => link)} />
        <CompilerProvider
          value={{
            config: {
              organizationId: deployment?.organizationId,
              projectId: deployment?.projectId,
              mode: 'production',
              router: {
                currentRoute,
                baseDeploymentPath,
                push: compilerRoutePush,
                isCustomDomain: true,
                customDomain: domain,
              },
              auth,
              api,
              accountId,
            },
          }}
        >
          <DynamicPropsWrapper>
            <EngineWrapper>
              <ProjectCompiler pages={pages} layouts={layouts} />
            </EngineWrapper>
          </DynamicPropsWrapper>
        </CompilerProvider>
      </ThemeProvider>
    </DeploymentNotFound>
  );
};

export const getServerSideProps = async (
  ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<any>> => {
  const domain = ctx.req.headers.host;

  const apiClient = new GraphQLClient(process.env.URL_ADMIN, {
    headers: { 'X-api-key': process.env.API_KEY_ADMIN },
  });

  try {
    const resp: { getLastDeploymentByDomain: Deployment } = await apiClient.request(
      print(GetLastProjectDeploymentByDomain),
      { domain },
    );

    return {
      props: {
        deployment: resp.getLastDeploymentByDomain,
        domain,
      },
    };
  } catch (error) {
    console.error(error);
    return {
      props: {
        projectId: null,
        deployment: null,
      },
    };
  }
};

export default DeploymentPage;
